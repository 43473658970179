var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm._l(_vm.processes, function (process, idx) {
          return _c(
            "q-chip",
            {
              key: idx,
              attrs: {
                outline: "",
                square: "",
                removable: "",
                color: "primary",
                "text-color": "white",
                icon: "memory",
                label: process.processName,
                title: process.processName,
              },
              on: { remove: (state) => _vm.removeProcess(state, process) },
              model: {
                value: process.check,
                callback: function ($$v) {
                  _vm.$set(process, "check", $$v)
                },
                expression: "process.check",
              },
            },
            [_c("q-tooltip", [_vm._v(_vm._s(process.processName))])],
            1
          )
        }),
        _c("q-chip", {
          attrs: {
            clickable: "",
            outline: "",
            square: "",
            color: "orange",
            "text-color": "white",
            icon: "add",
            label: "LBLADD",
            title: "추가",
          },
          on: { click: _vm.addProcess },
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-8" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "평가대상 목록 - 작업/작업단계/유해위험요인",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.grid.data,
              merge: _vm.grid.merge,
              filtering: false,
              columnSetting: false,
              editable: _vm.editable && !_vm.param.disabled,
              selection: "multiple",
              rowKey: "col6",
            },
            on: { innerBtnClicked: _vm.innerBtnClicked },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.param.disabled
                      ? _c("c-btn", {
                          attrs: { label: "LBLADD", icon: "add" },
                          on: { btnClicked: _vm.addSop },
                        })
                      : _vm._e(),
                    _vm.editable && !_vm.param.disabled
                      ? _c("c-btn", {
                          attrs: { label: "LBLREMOVE", icon: "remove" },
                          on: { btnClicked: _vm.remove },
                        })
                      : _vm._e(),
                    _vm.editable && !_vm.param.disabled
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-4" },
      [
        _c(
          "q-form",
          { ref: "editForm" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "작업/작업단계/유해위험요인 추가" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                disabled: _vm.disabled4,
                                label: "LBLAPPLY",
                                icon: "check",
                              },
                              on: { btnClicked: _vm.accepts },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled1,
                          required: true,
                          label: "LBLPROCESS",
                          name: "processName",
                        },
                        model: {
                          value: _vm.job.processName,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "processName", $$v)
                          },
                          expression: "job.processName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled2,
                          required: true,
                          label: "LBLAMOUNT",
                          name: "col1",
                        },
                        model: {
                          value: _vm.job.col1,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "col1", $$v)
                          },
                          expression: "job.col1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled2,
                          required: true,
                          label: "작업설명",
                          name: "col2",
                        },
                        model: {
                          value: _vm.job.col2,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "col2", $$v)
                          },
                          expression: "job.col2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled3,
                          required: true,
                          label: "작업단계",
                          name: "col3",
                        },
                        model: {
                          value: _vm.job.col3,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "col3", $$v)
                          },
                          expression: "job.col3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled3,
                          required: true,
                          label: "작업단계설명",
                          name: "col4",
                        },
                        model: {
                          value: _vm.job.col4,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "col4", $$v)
                          },
                          expression: "job.col4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled4,
                          required: true,
                          label: "유해위험요인",
                          name: "col5",
                        },
                        model: {
                          value: _vm.job.col5,
                          callback: function ($$v) {
                            _vm.$set(_vm.job, "col5", $$v)
                          },
                          expression: "job.col5",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }