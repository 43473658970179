<template>
  <div class="row">
    <div class="col-12">
      <q-chip
        v-for="(process, idx) in processes"
        :key="idx"
        outline square
        removable
        color="primary"
        text-color="white"
        icon="memory"
        :label="process.processName"
        :title="process.processName"
        v-model="process.check"
        @remove="state => removeProcess(state, process)"
      >
        <q-tooltip>{{ process.processName }}</q-tooltip>
      </q-chip>
      <q-chip
        clickable
        outline square
        color="orange"
        text-color="white"
        icon="add"
        label="LBLADD"
        title="추가"
        @click="addProcess"
      />
    </div>
    <div class="col-md-12 col-lg-8">
      <c-table
        ref="table"
        title="평가대상 목록 - 작업/작업단계/유해위험요인"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :merge="grid.merge"
        :filtering="false"
        :columnSetting="false"
        :editable="editable&&!param.disabled"
        selection="multiple"
        rowKey="col6"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!param.disabled" label="LBLADD" icon="add" @btnClicked="addSop" />
            <c-btn v-if="editable&&!param.disabled" label="LBLREMOVE" icon="remove" @btnClicked="remove" />
            <c-btn v-if="editable&&!param.disabled" label="LBLSAVE" icon="save" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-md-12 col-lg-4">
      <q-form ref="editForm">
        <c-card title="작업/작업단계/유해위험요인 추가" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable" :disabled="disabled4" label="LBLAPPLY" icon="check" @btnClicked="accepts"/>
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <c-text
                :editable="editable"
                :disabled="disabled1"
                :required="true"
                label="LBLPROCESS"
                name="processName"
                v-model="job.processName">
              </c-text>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable"
                :disabled="disabled2"
                :required="true"
                label="LBLAMOUNT"
                name="col1"
                v-model="job.col1">
              </c-text>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable"
                :disabled="disabled2"
                :required="true"
                label="작업설명"
                name="col2"
                v-model="job.col2">
              </c-text>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable"
                :disabled="disabled3"
                :required="true"
                label="작업단계"
                name="col3"
                v-model="job.col3">
              </c-text>
            </div>
            <div class="col-6">
              <c-text
                :editable="editable"
                :disabled="disabled3"
                :required="true"
                label="작업단계설명"
                name="col4"
                v-model="job.col4">
              </c-text>
            </div>
            <div class="col-12">
              <c-text
                :editable="editable"
                :disabled="disabled4"
                :required="true"
                label="유해위험요인"
                name="col5"
                v-model="job.col5">
              </c-text>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'jsa-target-process',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'col1' },
          { index: 2, colName: 'col1' },
          { index: 3, colName: 'col3' },
          { index: 4, colName: 'col3' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: true,
            innerBtn: true,
            btns: [
              { label: '작업추가', icon: 'add', }
            ]
          },
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLAMOUNT',
            align: 'center',
            sortable: true,
            innerBtn: true,
            btns: [
              { label: '작업단계추가', icon: 'add', }
            ]
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업설명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '작업단계',
            align: 'center',
            sortable: true,
            innerBtn: true,
            btns: [
              { label: '유해위험요인추가', icon: 'add', }
            ]
          },
          {
            name: 'col4',
            field: 'col4',
            label: '작업단계설명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '유해위험요인',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '550px'
      },
      job: {
        processCd: '',
        processName: '',
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '',
        col6: '',
      },
      disabled1: true,
      disabled2: true,
      disabled3: true,
      disabled4: true,
      rowIndex: 0,
      processes: [
        { processCd: 'A', processName: '공정A', check: true, },
        { processCd: 'B', processName: '공정B', check: true, },
        { processCd: 'C', processName: '공정C', check: true, },
        { processCd: 'D', processName: '공정D', check: true, },
        { processCd: 'E', processName: '공정E', check: true, },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        { 
          processCd: 'A', 
          processName: '공정A', 
          col1: '작업1', 
          col2: '작업1 - 설명', 
          col3: '작업단계1', 
          col4: '작업단계1 - 설명', 
          col5: '기계적(설비)요인-협착위험 부분(감김, 끼임)', 
          col6: '1',
        },
        { 
          processCd: 'A', 
          processName: '공정A', 
          col1: '작업1', 
          col2: '작업1 - 설명', 
          col3: '작업단계1', 
          col4: '작업단계1 - 설명', 
          col5: '기계적(설비)요인-위험한 표면(절단, 베임, 긁힘)', 
          col6: '2',
        },
        { 
          processCd: 'A', 
          processName: '공정A', 
          col1: '작업1', 
          col2: '작업1 - 설명', 
          col3: '작업단계2', 
          col4: '작업단계2 - 설명', 
          col5: '기계적(설비)요인-넘어짐', 
          col6: '3',
        },
        { 
          processCd: 'A', 
          processName: '공정A', 
          col1: '작업2', 
          col2: '작업2 - 설명', 
          col3: '작업단계a', 
          col4: '작업단계a - 설명', 
          col5: '기계적(설비)요인-넘어짐', 
          col6: '4',
        },
        { 
          processCd: 'B', 
          processName: '공정B', 
          col1: '작업3', 
          col2: '작업3 - 설명', 
          col3: '작업단계3-1', 
          col4: '작업단계3-1 - 설명', 
          col5: '기계적(설비)요인-넘어짐', 
          col6: '5',
        },
      ]
    },
    removeProcess(state, process) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '제외하실 경우 해당 공정에 해당하는\n\r평가대상 목록도 변경됩니다.\n\r진행하시겠습니까? ',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          process.check = false
        },
        // 취소 callback 함수
        cancelCallback: () => {
          process.check = true
        },
      });
    },
    addProcess() {
      window.getApp.$emit('ALERT', {
        title: 'LBLGUIDE', // 안내
        message: '준비중',
        type: 'info', // success / info / warning / error
      });
    },
    addSop() {
      window.getApp.$emit('ALERT', {
        title: 'LBLGUIDE', // 안내
        message: 'SOP 화면 준비중',
        type: 'info', // success / info / warning / error
      });
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, { col6: item.col6 })
        })
      }
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      if (col.name === 'processName') {
        this.addJob(props)
      } else if (col.name === 'col1') {
        this.addJobDetail(props)
      } else if (col.name === 'col3') {
        this.addRiskHazard(props)
      }
    },
    addJob(props) {
      this.disabled1 = true;
      this.disabled2 = false;
      this.disabled3 = false;
      this.disabled4 = false;
      let date = new Date();
      this.$_.extend(this.job, { 
        processCd: props.row.processCd, 
        processName: props.row.processName, 
        col1: '', 
        col2: '', 
        col3: '', 
        col4: '', 
        col5: '', 
        col6: String(date.getTime()),
      });
    },
    addJobDetail(props) {
      this.disabled1 = true;
      this.disabled2 = true;
      this.disabled3 = false;
      this.disabled4 = false;
      let date = new Date();
      this.$_.extend(this.job, { 
        processCd: props.row.processCd, 
        processName: props.row.processName, 
        col1: props.row.col1, 
        col2: props.row.col2, 
        col3: '', 
        col4: '', 
        col5: '', 
        col6: String(date.getTime()),
      });
    },
    addRiskHazard(props) {
      this.disabled1 = true;
      this.disabled2 = true;
      this.disabled3 = true;
      this.disabled4 = false;
      let date = new Date();
      this.$_.extend(this.job, { 
        processCd: props.row.processCd, 
        processName: props.row.processName, 
        col1: props.row.col1, 
        col2: props.row.col2, 
        col3: props.row.col3, 
        col4: props.row.col4, 
        col5: '', 
        col6: String(date.getTime()),
      });
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.grid.data.splice(this.rowIndex, 0, this.$_.clone(this.job));
          this.disabled1 = true;
          this.disabled2 = true;
          this.disabled3 = true;
          this.disabled4 = true;
          Object.assign(this.$data.job, this.$options.data().job);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '필수 입력값을 입력해 주세요.', // 필수 입력값을 입력해 주세요.
            type: 'warning', // success / info / warning / error
          });
        }
      });
    },
  }
};
</script>
